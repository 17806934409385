import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class DashboardService {
  getTodayStats(client, site_id = null, timeframe, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
      timeframe: timeframe,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/stats?" + params.toString();
    return axios.get(url, { headers: authHeader() });
  }
  getRecoveryChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/fuel?" + params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getDashboardData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url = "https://api.varsanpr.com/api/dashboard?" + params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getParkingChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/parking?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getPotentialBlacklistChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/blacklistevents?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getDriveoffReportedChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/driveoff?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getBlacklistedVehicleChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/blacklistedvehicles?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getNumberOfClaimsChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/submittedclaims?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getCancellationsChartData(client, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/charts/cancellations?" +
      params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getPreviousYearData(client_id, site_id = null, group_id = null) {
    let params = new URLSearchParams({
      client_id: client_id,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      "https://api.varsanpr.com/api/dashboard/previous?" + params.toString();

    return axios.get(url, { headers: authHeader() });
  }

  getBlacklistSavings(client_id, site_id, start, end, group_id = null) {
    let params = new URLSearchParams({
      client_id: client_id,
      start: start,
      end: end,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      `https://api.varsanpr.com/api/misc/blacklistsaving?` + params.toString();
    return axios.get(url, { headers: authHeader() });
  }

  getStatusBreakdown(client_id, site_id, group_id = null) {
    let params = new URLSearchParams({
      client_id: client_id,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      `https://api.varsanpr.com/api/dashboard/status?` + params.toString();
    return axios.get(url, { headers: authHeader() });
  }
  getDailyStats(client_id, site_id, group_id = null) {
    let params = new URLSearchParams({
      client_id: client_id,
    });
    if (site_id !== null && site_id !== -1) {
      params.append("site_id", site_id);
    }
    if (group_id !== null && group_id !== -1) {
      params.append("group_id", group_id);
    }
    let url =
      `https://api.varsanpr.com/api/dashboard/stats/daily?` + params.toString();
    return axios.get(url, { headers: authHeader() });
  }
  getAwaitingEvidenceIncidents(site_id) {
    let params = new URLSearchParams({
      site_id: site_id,
    });
    let url = 
      `https://api.varsanpr.com/customer/incident/incidents/awaiting_evidence?` + params.toString();
    return axios.get(url, { headers: authHeader() });
  }
}

export default new DashboardService();
